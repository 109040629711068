@import "./../../../assets/sass/var.scss";
@import "./../../../assets/sass/theme.scss";

.rounded-list {
    display: flex;
    gap: 5px;
    align-items: center;
    position: relative;

    overflow-y: hidden;
    padding-right: 30px;
    @extend .custom-scrollbar;

    .item {
        border-radius: 999px;
        padding: 5px 15px;

        display: flex;
        gap: 5px;
        align-items: center;

        font-family: "Config Rounded";
        font-weight: 600;

        cursor: pointer;

        border: white solid 1px;

        &.current {
            background-color: white;

            .name {
                color: $primary;
            }

            .number {
                color: #5078df88;
            }
        }

        .name {
            color: white;
            white-space: nowrap;
        }

        .number {
            color: #ffffff88;
        }
    }
}
