@import "./../../../assets/sass/var.scss";

.mobile-menu {
    background-color: $primary;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 48px 56px;
    gap: 70px;

    &.close {
        display: none;
    }

    header {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;

        img {
            height: 100%;
            max-height: 50px;
            max-width: calc(100% - 40px);
        }

        .close {
            height: 37px;
            width: 37px;
            border-radius: 999px;
            background-color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            .icon {
                fill: $primary;
                width: 13px;
            }

            &:hover {
                background-color: $primary-dark;

                .icon {
                    fill: white;
                }
            }
        }
    }

    > .content {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: flex-start;
        flex-grow: 1;
        gap: 10px;

        .title {
            font-family: 'CHEDROS', sans-serif;
            font-size: 0.9rem;
            font-weight: bold;
            color: #e9eef266;
            letter-spacing: 0.1rem;
        }

        .menu-item {
            font-family: 'CHEDROS', sans-serif;
            font-size: 1.3rem;
            font-weight: bold;
            color: white;
            letter-spacing: 0.05rem;
            cursor: pointer;
        }
    }

    footer {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;

        .button {
            min-width: 80%;
        }

        hr {
            border-top: 1px solid #ffffff80;
            width: 100%;
        }

        .privacy-policy {
            display: flex;
            gap: 20px;
            position: relative;
            top: -5px;

            a {
                color: #e9eef280;
                font-family: "Config Rounded", sans-serif;
                font-size: 0.9rem;
                font-weight: 600;
            }
        }

        .webdesign-by {
            display: flex;
            gap: 4px;
            align-items: center;

            span {
                color: white;
                font-family: "Config Rounded", sans-serif;
                font-size: 1rem;
                font-weight: bold;
                text-transform: uppercase;
            }

            svg {
                height: 0.6rem;
                fill: white;
            }
        }
    }
}


// desktop
@media (min-width: $desktop_min_width) {
    .mobile-menu {
        display: none;
    }
}