@import "assets/sass/var.scss";

.go-to-another-penguin {
    display: none;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding-bottom: 120px;

    h3 {
        font-family: "Config Rounded", sans-serif;
        font-weight: 600;
        font-size: 1.4rem;
        color: white;
    }

    .content {
        position: relative;

        .arrow {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: 0;
            bottom: 0;
            pointer-events: none;
            z-index: 1;

            &.left {
                left: -30px;

                svg {
                    left: -2px;
                }
            }

            &.right {
                right: -30px;

                svg {
                    right: -2px;
                }
            }

            .clickable {
                pointer-events: all;
                border-radius: 999px;
                background-color: white;
                width: 44px;
                height: 44px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                &:hover {
                    background-color: $primary;

                    svg {
                        fill: white;
                    }
                }

                > svg {
                    position: relative;
                    width: 22px;
                    height: 22px;
                    fill: $primary;
                }
            }
        }

        .desktop-info {
            display: none;
        }

        .penguins {
            width: 250px;
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            z-index: 0;

            .penguin {
                width: 30%;
                border-radius: 999px;
                border: solid 3px white;
                overflow: hidden;
                position: relative;

                // make square ratio
                &::after {
                    content: "";
                    display: block;
                    padding-bottom: 100%;
                }

                &.current {
                    width: 34%;
                }

                &:not(.current) {
                    opacity: 0.3;
                }

                &.empty {
                    opacity: 0;
                }

                img {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}

// desktop
@media (min-width: $desktop_min_width) {
    .go-to-another-penguin {
        display: flex;
        grid-area: head;
        padding-bottom: 0;

        h3 {
            display: none;
        }

        .content {
            background-color: white;
            border-radius: 9999px;

            display: flex;
            align-items: center;

            .arrow {
                &.left {
                    left: -40px;

                    svg {
                        left: -1px;
                    }
                }

                &.right {
                    right: -40px;

                    svg {
                        right: -1px;
                    }
                }

                .clickable {
                    width: 32px;
                    height: 32px;

                    > svg {
                        width: 16px;
                        height: 16px;
                    }
                }
            }

            .desktop-info {
                display: block;
                padding: 0.5rem 1.4rem 0.5rem 2.4rem;

                h2 {
                    font-family: "CHEDROS", sans-serif;
                    font-weight: 600;
                    font-size: 1.6rem;
                    color: $primary;
                    line-height: 1;
                }

                .info {
                    font-family: "Config Rounded", sans-serif;
                    font-weight: 600;
                    font-size: 1.2rem;
                    color: $primary-dark;
                    text-transform: uppercase;
                }
            }

            .penguins {
                height: calc(0.5rem + 0.5rem + 1.6rem + 1.2rem);
                width: calc(0.5rem + 0.5rem + 1.6rem + 1.2rem);

                display: flex;
                justify-content: center;
                align-items: center;

                .penguin {
                    width: calc(100% - 6px) !important;
                    border: none;

                    &:not(.current) {
                        display: none;
                    }
                }
            }
        }
    }
}
