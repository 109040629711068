@import "./../../assets/sass/var.scss";

.error-page {
    min-height: 100vh;
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    .content {
        background-color: white;
        border-radius: 10px;
        padding: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        img {
            margin-bottom: 1.5rem;
        }

        h1 {
            font-family: "CHEDROS", sans-serif;
            font-size: 3.8rem;
            font-weight: bold;
            color: $primary-dark;
        }

        p {
            font-family: "Config Rounded", sans-serif;
            font-size: 1.3rem;
            color: $primary-dark;
            margin-bottom: 1.5rem;
        }

        a:hover {
            text-decoration: none;
        }
    }
}

// desktop
@media (min-width: $desktop_min_width) {
    body {
        // background-image: url("./../../assets/img/main-background.jpg") !important;
    }

    .error-page {
        padding-top: 100px;
        background-color: transparent;

        > .content {
            min-width: 500px;
        }
    }
}
