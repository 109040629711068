@import "./../../../assets/sass/var.scss";

#navigation-type {
    display: flex;
    justify-content: flex-start;
    gap: 3px;

    .item {
        font-size: 1.2rem;
        font-family: "Config Rounded", sans-serif;
        font-weight: 600;
        padding: 6px 20px;
        cursor: pointer;

        &.active {
            background-color: $primary;
            color: white;
            border-radius: 999px;
        }

        &:hover:not(.active) {
            color: $primary;
        }
    }
}
