@import "assets/sass/var.scss";
@import "assets/sass/theme.scss";

#root {
    display: flex;
    flex-direction: column;
    gap: 20px;

    border-radius: 40px 40px 0 0;
    position: relative;
    top: -38px;

    padding: 15px;

    height: calc(100vh - 176px + 40px);

    header {
        display: none;
    }

    .items-inventory {
        top: 2px;

        .items-inventory-content {
            justify-content: center;
        }
    }
}

@media (min-width: $desktop_min_width) {
    #root {
        padding-top: 150px;
        top: 0px;
        border-radius: 0;
        gap: 0px;

        header {
            display: block;
        }

        h2 {
            font-family: "CHEDROS", sans-serif;
            font-weight: bold;
            font-size: 1.8rem;

            text-align: center;
            color: white;
        }

        .items-inventory {
            top: 0px;
            height: 100%;
        }
    }
}
