@import "assets/sass/var.scss";

.desktop {
    display: none;
}

.price {
    color: gray;
}

// desktop
@media (min-width: $desktop_min_width) {
    .mobile {
        display: none;
    }

    .desktop {
        display: block;
        flex-basis: auto;
        background-color: white;
        border-radius: 10px;
        position: relative;
        width: 250px;
        padding-top: 250px;
        cursor: pointer;
        overflow: hidden;

        .img {
            width: 250px;
            height: 250px;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
        }

        .infos {
            padding: 15px;

            .name {
                font-family: "CHEDROS", sans-serif;
                font-weight: bold;
                font-size: 1.4rem;
            }

            .rarity {
                margin-top: 0.6rem;
                font-family: "CHEDROS", sans-serif;
                font-weight: bold;
                color: $primary;
            }
        }
    }
}
