@import "./../../../assets/sass/var.scss";

.set-price {
    display: flex;
    flex-direction: column;
    align-items: center;

    gap: 10px;

    h3 {
        font-family: "CHEDROS", sans-serif;
        font-size: 1.6rem;
        font-weight: bold;
        color: $primary;
    }

    .input-price {
        color: $primary;
        display: flex;
        justify-content: space-between;
        width: 100%;
        max-width: 300px;
        gap: 10px;
        flex-shrink: 1;

        .control {
            font-family: "Config Rounded", sans-serif;
            font-size: 3.2rem;
            background-color: white;
            border-radius: 999px;
            width: 3.6rem;
            height: 3.6rem;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-grow: 0;
            flex-shrink: 0;
            cursor: pointer;
            @extend .noselect;

            &:hover {
                background-color: $primary;
                color: white;
            }

            span {
                position: relative;
                top: -0.2rem;
                left: 0.1rem;
            }
        }

        .input {
            font-family: "Config Rounded", sans-serif;
            font-size: 1.3rem;
            font-weight: bold;
            background-color: white;
            border-radius: 999px;
            display: flex;
            align-items: center;
            padding: 0 30px;
            flex-shrink: 1;
            overflow: hidden;
            justify-content: flex-end;

            input {
                font-family: "Config Rounded", sans-serif;
                font-weight: bold;
                color: $primary;
                border: none;
                background-color: transparent;
                width: 100%;
                text-align: center;
                padding-right: 5px;

                &:focus-visible {
                    outline: none;
                }

                /* Chrome, Safari, Edge, Opera */
                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }

                /* Firefox */
                &[type="number"] {
                    -moz-appearance: textfield;
                }
            }
        }
    }

    .floor-price {
        font-family: "Config Rounded", sans-serif;
        color: #080d26b0;
    }
}

// source: https://stackoverflow.com/questions/826782/how-to-disable-text-selection-highlighting
.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}
