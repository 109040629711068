@import "./../../../assets/sass/var.scss";

.type-in-marketplace {
    background-color: $background;
    padding-bottom: 60px;

    > * {
        padding-left: 30px;
        padding-right: 30px;
    }

    > .background-header {
        display: none;
    }

    > .icon {
        display: none;
    }

    > h1 {
        font-family: "CHEDROS", sans-serif;
        font-weight: bold;
        position: relative;
        top: -2rem;
        font-size: 2rem;
        color: $primary;
        text-align: center;
    }

    > .description {
        font-family: "Config Rounded", sans-serif;
        text-align: center;
        margin-top: 1rem;
        padding-left: 40px;
        padding-right: 40px;
    }

    > .labels {
        display: flex;
        justify-content: center;
        margin-top: 40px;
        margin-bottom: 30px;
        gap: 15px;
    }
}

// desktop
@media (min-width: $desktop_min_width) {
    .type-in-marketplace {
        padding-top: 120px;
        max-width: 1400px;
        margin: auto;
        margin-bottom: 40px;
        min-height: calc(100vh - 220px); // for footer to be at the bottom

        > .background-header {
            display: block;
            height: 350px;
            width: 100%;
            background-position: center;
            background-size: cover;
            position: absolute;
            top: 0px;
            left: 0px;

            &.penguins {
                background-image: url("/assets/img/background/header-penguins.jpg");
            }

            &.items {
                background-image: url("/assets/img/background/header-items.jpg");
            }

            &.eggs {
                background-image: url("/assets/img/background/header-eggs.png");
            }
        }

        > .icon {
            position: relative;
            background-color: white;
            border-radius: 6px;
            margin: 140px auto 3rem auto;
            width: fit-content;
            aspect-ratio: 1 / 1;
            padding: 15px;

            display: flex;
            align-items: center;
            justify-content: center;

            overflow: hidden;

            &.iconNoBorder {
                padding: 0;

                img {
                    max-height: 127px;
                    max-width: 127px;
                    border-radius: 6px;
                }
            }

            img {
                height: 100%;
                width: 100%;
                max-height: 97px;
                max-width: 97px;
            }
        }

        > h1 {
            font-family: "CHEDROS", sans-serif;
            font-weight: bold;
            position: relative;
            top: -2rem;
            font-size: 3rem;
            color: $primary-dark;
            text-align: center;
        }
    }
}
