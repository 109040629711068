@import "./../../../assets/sass/var.scss";
@import "./../../../assets/sass/theme.scss";

#popup-from-bottom {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 50;
    background-color: rgba(8, 13, 38, 0.8);

    &.is-close {
        display: none;
    }

    > .content {
        background-color: $background;
        position: absolute;
        top: 10%;
        bottom: 0;
        right: 0;
        left: 0;
        border-radius: 80px 80px 0 0;

        h3 {
            margin: 1rem 60px;
            text-align: center;
            font-family: "CHEDROS", sans-serif;
            font-size: 2rem;
            font-weight: bold;
            letter-spacing: 0.05rem;

            &.desktop {
                display: none;
            }
        }

        .rounded-list {
            display: none;
        }

        .items {
            margin-right: 35px;
            margin-left: 35px;
            position: absolute;
            top: 4rem;
            bottom: 100px;
            left: 0;
            right: 0;
            overflow-y: auto;

            @extend .custom-scrollbar;

            .content {
                display: flex;
                flex-wrap: wrap;
                flex-direction: row;
                justify-content: flex-start;
                align-content: flex-start;
                gap: 18px 10px;

                .item {
                    color: white;
                    border-radius: 10px;
                    min-width: calc(50% - 5px);
                    position: relative;
                    overflow: hidden;
                    cursor: pointer;

                    &::before {
                        content: "";
                        display: block;
                        padding-bottom: 100%;
                    }

                    &.is-selected {
                        border: $primary 3px solid;

                        .count {
                            top: 5px;
                            right: 5px;
                        }
                    }

                    .thumbnail,
                    .model {
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        width: 100%;
                        height: 100%;
                    }

                    .count {
                        position: absolute;
                        top: 8px;
                        right: 8px;
                        color: white;
                        background-color: $primary;
                        border-radius: 99px;
                        width: 1.8rem;
                        height: 1.8rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        font-family: "Config Rounded", sans-serif;
                        font-size: 1.1rem;
                        font-weight: normal;
                    }

                    .name {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        text-align: center;
                        padding-bottom: 0.3rem;
                        font-family: "CHEDROS", sans-serif;
                        font-size: 1.3rem;
                        font-weight: bold;
                        word-wrap: break-word;
                        display: inline-block;
                    }

                    .back-name {
                        position: absolute;
                        right: 0;
                        left: 0;
                        bottom: -1px;
                        height: 3rem;
                        background: linear-gradient(to top, #080d26ff 0%, #080d2600 100%);
                    }
                }
            }
        }

        .controls {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 100px;
            padding: 0 35px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 15px;
        }
    }
}

// desktop
@media (min-width: $desktop_min_width) {
    #popup-from-bottom {
        grid-area: popup;

        position: relative;
        top: auto;
        bottom: auto;
        left: auto;
        right: auto;
        background-color: transparent;

        &.is-close {
            display: block;
        }

        > .content {
            background-color: transparent;

            h3 {
                display: none;

                &.desktop {
                    display: block;
                    color: $primary;
                }
            }

            .rounded-list {
                display: flex;
                margin-left: 35px;
                margin-right: 35px;
            }

            .items {
                margin-top: 40px;
                padding-top: 15px;
                bottom: 0;

                .content {
                    gap: 18px;

                    .item {
                        min-width: calc(30% - 7px);
                        overflow: visible;

                        &.is-hidden {
                            display: none;
                        }

                        &.is-disabled {
                            cursor: not-allowed;
                        }

                        .thumbnail,
                        .model,
                        .name,
                        .back-name {
                            border-radius: 10px;
                        }
                    }
                }
            }

            .controls {
                display: none;
            }
        }
    }
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}
@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}
@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
