@import "assets/sass/var.scss";

.social {
    display: flex;
    gap: 10px;

    .icon {
        overflow: hidden;
        border-radius: 9999px;
        border: $primary 2px solid;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        background-color: white;
        fill: $primary;

        svg {
            width: 15px;
        }

        &:hover {
            cursor: pointer;
            fill: $primary-hover;
            border-color: $primary-hover;
        }
    }
}
