@import "./../../assets/sass/var.scss";
@import "./../../assets/sass/theme.scss";

#home {
    background-color: $background;

    .top-page {
        min-height: 80vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 25px 10px;
        gap: 120px;

        background-image: url("./../../assets/img/main-background.jpg");
        background-size: cover;
        background-position: calc(50vh + 50vw) center;
        background-attachment: fixed;

        img {
            height: 55px;
        }

        .info {
            h1 {
                font-family: "CHEDROS", sans-serif;
                font-weight: bold;
                font-size: 3.5rem;
                color: white;
                text-align: center;
                line-height: 1;
            }

            p {
                font-family: "Config Rounded", sans-serif;
                font-size: 1.2rem;
                text-align: center;
                color: white;
            }
        }

        .button {
        }
    }

    --padding-section: 25px;

    > section {
        background-color: $background;
        overflow: hidden;
        padding: 25px var(--padding-section);

        > h2 {
            font-family: "CHEDROS", sans-serif;
            font-weight: bold;
            font-size: 1.8rem;
            color: $dark;
            margin-bottom: 0.5rem;
        }

        .content {
        }
    }

    > .categories,
    > .chose-category {
        padding-right: 0;
        padding-left: 0;

        > h2 {
            padding-right: var(--padding-section);
            padding-left: var(--padding-section);
        }

        .content {
            display: flex;
            flex-direction: column;
            gap: 15px;
            flex-wrap: nowrap;
            overflow: auto;
            padding-right: var(--padding-section);
            padding-left: var(--padding-section);
            padding-bottom: 15px;

            > * {
                height: 200px;
            }
        }

        .items h2 {
            color: white !important;
        }
    }

    > .explore-all {
        > .content {
            display: grid;
            grid-gap: 10px;
            grid-template-columns: repeat(2, 1fr);
            @extend .custom-scrollbar;

            &::-webkit-scrollbar-track {
                margin: var(--padding-section);
            }
        }

        > .control {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 30px;
            margin-bottom: 50px;

            .button {
                min-width: 60%;
            }
        }
    }

    > .customize-your-penguins {
        background-image: url("./../../assets/img/main-background.jpg");
        background-size: cover;
        background-position: calc(50vh + 50vw) center;
        background-attachment: fixed;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h2 {
            color: white;
            text-align: center;
            font-size: 2.6rem;
        }

        .subtitle {
            color: white;
            text-align: center;
            font-family: "Config Rounded", sans-serif;
            font-size: 1.1rem;
            margin-bottom: 1rem;
        }

        img {
            max-width: 100%;
        }

        .button {
            min-width: 60%;
            margin-top: 3rem;
        }
    }

    .global-unique-style {
        > .give-unique-style {
            background-image: url("./../../assets/img/penguins_gang_background.jpg");
            background-size: cover;
            background-position: center;
            min-height: 120vw;

            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;

            padding: 25px var(--padding-section);

            h2 {
                font-family: "CHEDROS", sans-serif;
                font-weight: bold;
                margin-bottom: 0.5rem;
                color: white;
                text-align: center;
                font-size: 2.6rem;

                span {
                    display: none;
                }
            }

            .button {
                min-width: 60%;
                margin-top: 3rem;
            }
        }

        > .highlighted-item {
            background-color: white;
            padding-bottom: 0;
            position: relative;
            display: flex;
            flex-direction: column;

            .info {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: stretch;
                position: absolute;
                top: 25px;
                left: 25px;
                right: 25px;

                h2 {
                    font-family: "Config Rounded", sans-serif;
                    font-size: 1.2rem;
                    font-weight: 300;
                }

                .name,
                .price {
                    font-family: "Config Rounded", sans-serif;
                    font-size: 1.5rem;
                }

                .price {
                    align-self: flex-end;
                    font-weight: bold;
                    color: $primary;
                }
            }
        }
    }

    > .sell-items {
        background-image: url("./../../assets/img/background_sell_and_buy.jpg");
        background-size: cover;

        text-align: center;
        display: grid;
        justify-items: center;
        grid-gap: 15px;
        grid-template-areas: "title" "subtitle" "image" "sell-button" "buy-button";

        h2 {
            color: white;
            text-align: center;
            font-size: 2.6rem;
            grid-area: title;
        }

        .subtitle {
            color: white;
            grid-area: subtitle;
        }

        img {
            grid-area: image;
            max-width: 100%;
        }

        .button {
            min-width: 60%;

            &.sell {
                grid-area: sell-button;
            }

            &.buy {
                grid-area: buy-button;
            }
        }
    }
}

// desktop
@media (min-width: $desktop_min_width) {
    #home {
        padding-top: 100px;
        background-image: url("./../../assets/img/main-background.jpg");
        background-size: 150%;
        background-position: calc(50vh + 77vw) center;
        background-attachment: fixed;

        .top-page {
            background: none;
        }

        --padding-section: 80px;

        > .explore-all {
            padding-right: 0;
            padding-left: 0;

            > h2 {
                padding-right: var(--padding-section);
                padding-left: var(--padding-section);
            }

            > .content {
                display: flex;
                overflow: auto;
                padding-right: var(--padding-section);
                padding-left: var(--padding-section);
                padding-bottom: 15px;
            }

            > .control {
                padding-right: var(--padding-section);
                padding-left: var(--padding-section);
            }
        }

        > .customize-your-penguins {
            display: grid;
            grid-template-areas:
                "title image"
                "subtitle image"
                "button image";
            grid-gap: 0;
            grid-template-columns: 1fr 1fr;
            justify-items: center;
            padding: 60px var(--padding-section);

            h2 {
                font-size: 5rem;
                line-height: 1;
                grid-area: title;
            }

            .subtitle {
                position: relative;
                top: -1rem;
                grid-area: subtitle;
            }

            img {
                max-width: 300px;
                justify-self: center;
                grid-area: image;
            }

            .button {
                min-width: auto;
                grid-area: button;
            }
        }

        .chose-category {
            .content {
                flex-direction: row;

                > * {
                    height: 365px;
                }
            }
        }

        .global-unique-style {
            display: flex;
            justify-content: center;
            padding: 60px;
            background-color: $background;

            > .give-unique-style {
                border-top-left-radius: 8px;
                border-bottom-left-radius: 8px;
                width: 40%;
                min-height: auto;
                height: 75vh;
                overflow: hidden;
                padding: 25px;

                h2 {
                    span {
                        display: inline;
                    }
                }
            }

            > .highlighted-item {
                border-bottom-right-radius: 8px;
                border-top-right-radius: 8px;
                width: 40%;
                height: 75vh;
                overflow: hidden;
                position: relative;

                img {
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                }

                .info {
                    gap: 1rem;

                    .name {
                        font-size: 2rem;
                    }
                }
            }
        }

        > .sell-items {
            background-image: url("./../../assets/img/background_sell_and_buy_desktop.jpg");
            grid-template-areas:
                "image image title title"
                "image image subtitle subtitle"
                "image image sell-button buy-button";
            grid-gap: 0;
            grid-template-columns: 1fr 1fr 2fr 2fr;
            padding: 0 var(--padding-section);

            h2 {
                font-size: 5rem;
                line-height: 1;
                margin-top: 60px;
            }

            .subtitle {
                position: relative;
                top: -1rem;
            }

            img {
                height: 100%;
                max-height: 450px;
                width: auto;
                justify-self: center;
            }

            .button {
                min-width: auto;
                margin-bottom: 60px;

                &.sell {
                    justify-self: end;
                    margin-right: 4px;
                }

                &.buy {
                    justify-self: start;
                    margin-left: 4px;
                }
            }
        }
    }
}
