@import "./../../assets/sass/var.scss";

#item-in-inventory {
    background-color: $background;

    > * {
        padding-left: 30px;
        padding-right: 30px;
    }

    .thumbnail {
        .img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 10px;
        }
    }

    > .infos {
        margin-top: 15px;
        display: flex;
        justify-content: space-between;

        .name {
            font-family: "CHEDROS", sans-serif;
            font-weight: bold;
            font-size: 1.8rem;
            color: $primary;
        }

        .share {
            display: none;
            height: 1.2rem;

            > svg {
                height: 100%;
                fill: $primary;
            }
        }
    }

    .actions {
        margin-top: 15px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        gap: 10px;

        &.in-market {
            flex-direction: column;
            gap: 5px;

            p {
                font-family: "Config Rounded", sans-serif;
                color: #080d2680;
            }
        }
    }

    hr {
        border-top: 1px solid #080d2680;
        margin-left: 30px;
        margin-right: 30px;
    }
}

// desktop
@media (min-width: $desktop_min_width) {
    #item-in-inventory {
        min-height: 100vh;
        padding-top: 120px;
        max-width: 1400px;
        margin: auto;

        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
            "thumbnail infos"
            "thumbnail actions"
            "thumbnail activity";
        grid-template-rows: auto auto 1fr;
        grid-gap: 10px;
        align-items: start;

        .thumbnail {
            grid-area: thumbnail;

            .img {
                @media (max-width: 1050px) {
                    margin-top: 100px;
                }
                max-height: min(80vh, 50vw);
                width: auto;
            }
        }

        .infos {
            grid-area: infos;
            display: grid;
            grid-template-columns: 1fr 1fr;

            .name {
                color: $dark;
            }

            .share {
                justify-self: end;
                display: inherit;
            }

            .rank {
                display: block;
                font-family: "Config Rounded", sans-serif;
                font-size: 1.2rem;
                color: #080d2680;

                .primary {
                    color: $primary;
                    font-weight: bold;
                }
            }
        }

        .actions {
            grid-area: actions;
        }

        .activity {
            grid-area: activity;
        }
    }
}
