@import "assets/sass/var.scss";
@import "assets/sass/theme.scss";
// body can't scrollable directly
// body {
//     overflow: hidden; // TODO: make overflow for specific page
// }

.mobile-header {
    background-image: url("./../../assets/img/bgHeaderMobile.jpg");
    background-size: cover;
}

#body-content {
    background-image: url("./../../assets/img/Bg_invetory_mobile_APC.jpg");
    background-size: 100%;
    background-repeat: no-repeat;

    border-radius: 40px 40px 0 0;
    position: relative;
    top: -38px;

    padding: 15px;

    display: flex;
    flex-direction: column;
    gap: 20px;

    height: calc(100vh - 176px + 38px);

    > header {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        h2 {
            font-family: "CHEDROS", sans-serif;
            font-weight: bold;
            font-size: 1.8rem;
        }

        .wallet-address {
            display: flex;
            justify-content: center;
            gap: 1em;
            width: 80%;

            span {
                max-width: 65%;
                text-overflow: ellipsis;
                overflow: hidden;
                font-size: 1.1rem;
                font-family: "Config Rounded Text", sans-serif;
            }

            .share {
                cursor: pointer;

                .icon {
                    fill: $primary;
                    width: 1.2em;
                }
            }
        }
    }

    .edit-background {
        display: none;
    }

    #filter {
        display: flex;
        flex-direction: column;
        gap: 10px;

        > .number-items {
            display: none;
            grid-template-columns: repeat(2, 1fr);
            gap: 10px;
            margin-bottom: 20px;

            .item {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                gap: 5px;
                padding: 10px;
                border: #080d26 solid 1px;
                border-radius: 6px;

                .number,
                .name {
                    font-family: "Config Rounded", sans-serif;
                    font-weight: 600;
                    font-size: 1.2rem;
                    text-transform: uppercase;
                }
            }
        }

        .title {
            display: flex;
            justify-content: flex-start;
            align-items: baseline;
            gap: 10px;

            h3 {
                font-family: "CHEDROS", sans-serif;
                font-weight: bold;
                font-size: 1.4rem;
                white-space: nowrap;
            }

            .number-items {
                font-family: "CHEDROS", sans-serif;
                font-weight: bold;
                font-size: 1.2rem;
                color: white;
                background-color: $primary;
                border-radius: 999px;
                line-height: initial;
                height: 2rem;
                width: 2rem;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .info {
                font-family: "Config Rounded", sans-serif;
                font-weight: 300;
                font-style: italic;
                color: #797979;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                line-height: 1.1;
            }
        }
    }

    .items-inventory {
        @extend .custom-scrollbar;
        position: relative;
        flex: 1 1 auto;
        height: auto;
        overflow: auto;

        .items-inventory-container {
            height: auto;
        }
    }
}

// desktop
@media (min-width: $desktop_min_width) {
    body {
        overflow: auto;
    }

    #desktop-header {
        display: block;
        width: 100%;
        height: 200px;
        background-image: url("./../../assets/img/T1-Bleu.jpg");
    }

    #body-content {
        display: grid;
        grid-gap: 0 25px;
        grid-template-areas:
            "header edit-background"
            "filter-items nav-type"
            "filter-items inventory"
            ". inventory"
            ". inventory"
            ". inventory"
            ". inventory"
            ". inventory"
            ". inventory"
            ". inventory";
        grid-template-columns: 350px 1fr;

        border-radius: 0;
        top: -80px;
        background: transparent;
        height: auto;
        max-height: 100vh;

        padding-top: 158px;
        top: unset;
        /* background-image: url('./../../assets/img/Bg_invetory_mobile_APC.jpg'); */
        // background-size: 100%;
        // background-repeat: no-repeat;
        min-height: 100vh;
        grid-template-rows: auto auto auto 1fr;
        grid-template-areas:
            "header edit-background"
            "filter-items nav-type"
            "filter-items inventory"
            ". inventory";

        &::before {
            content: "";
            position: absolute;
            top: 226px;
            left: 0;
            bottom: 0;
            right: 0;
            background-image: url("./../../assets/img/Bg_invetory_mobile_APC.jpg");
            background-size: 100%;
            background-repeat: no-repeat;
            z-index: -1;
        }

        > header {
            grid-area: header;
            background-color: white;
            border-radius: 15px 15px 0 0;
            padding: 20px;
            z-index: 1;

            h2 {
                font-family: "Config Rounded", sans-serif;
                font-weight: bold;
                font-size: 1.4rem;
                text-transform: uppercase;
            }

            .wallet-address {
                width: 100%;
            }
        }

        .edit-background {
            grid-area: edit-background;
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            padding-right: 40px;

            .content {
                display: flex;
                gap: 7px;
                border-radius: 999px;
                border: 2px solid rgba(255, 255, 255, 0.8);
                padding: 5px 15px;

                position: relative;
                top: -25px;

                p {
                    font-family: "Config Rounded", sans-serif;
                    font-weight: 500;
                    font-size: 1.2rem;
                    color: rgba(255, 255, 255, 0.8);
                    text-transform: capitalize;
                }

                .icon {
                    fill: rgba(255, 255, 255, 0.8);
                    height: 1.2rem;
                }
            }
        }

        #filter {
            grid-area: filter-items;
            background-color: white;
            border-radius: 0 0 15px 15px;
            padding: 20px;

            box-shadow: 0px 0px 20px rgba(8, 13, 38, 0.2);

            > .number-items {
                display: grid;
            }

            .title {
                display: none;
            }
        }

        > .navigation-type {
            grid-area: nav-type;
            margin-bottom: 20px;
        }

        .items-inventory {
            grid-area: inventory;
            padding: 0;
        }
    }
}
