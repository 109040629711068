@import "./../../../assets/sass/var.scss";

.big-category {
    border-radius: 10px;
    overflow: hidden;
    position: relative;

    min-width: auto;
    width: auto;
    height: 365px;

    &::after {
        content: "";
        display: block;
        padding-bottom: 25%;
        // padding-bottom: calc(368 / 260 * 55vw);
    }

    img {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        min-width: 100%;
        width: 0;
        min-height: 100%;
        height: 0;

        height: auto;
        object-fit: cover;
    }

    h2 {
        position: absolute;
        top: 18px;
        left: 25px;
        right: 25px;
        text-align: center;
        font-family: "CHEDROS", sans-serif;
        font-weight: bold;
        font-size: 2.5rem;
        color: #080d26;
    }

    .button {
        position: absolute;
        bottom: 18px;
        left: 25px;
        right: 25px;
    }
}
