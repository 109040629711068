@import "./../../../../../assets/sass/var.scss";

.attribute {
    display: grid;
    grid-template-columns: 1fr 15% 10%;
    padding: 10px 15px;
    border-radius: 999px;

    color: white;
    font-family: "Config Rounded Text", sans-serif;
    font-size: 1.2rem;

    cursor: pointer;

    &.active {
        background-color: rgba(255, 255, 255, 0.15);

        .check {
            opacity: 1;
        }
    }

    .check {
        opacity: 0;

        svg {
            fill: white;
            width: 1.3rem;
            height: auto;
        }
    }
}
