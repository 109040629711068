.itemsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;

    .item {
        width: 250px;
        height: 250px;
    }
}
