@import "./../../assets/sass/var.scss";

.render {
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    width: 100%;
    background-color: white;

    &.hasBackground {
        background-color: transparent;
    }

    &::after {
        content: "";
        display: block;
        padding-bottom: 100%;
    }

    img {
        width: 100%;
        position: absolute;
    }
}

// desktop
@media (min-width: $desktop_min_width) {
    .render {
        max-width: calc(100vh - 109px - 55px - 2.2rem - 2.2rem - 25px - 25px - 1.6rem - 1.2rem - 0.8rem - 50px);
        overflow: initial;

        img {
            border-radius: 10px;
        }

        &::before {
            content: "";
            position: absolute;
            inset: -12px;
            border: white 4px solid;
            border-radius: 15px;
        }
    }
}
