@import "./../../../../assets/sass/var.scss";

.item-list {
    padding: 25px 50px;
    border-bottom: 1px solid white;

    &.close {
        .content {
            display: none;
        }
    }

    &.open {
        .header {
            .toggle {
                svg {
                    transform: rotate(180deg);
                }
            }
        }
    }

    .header {
        display: flex;
        gap: 25px;
        cursor: pointer;

        .icon {
            img {
                width: 24px;
            }

            svg {
                fill: white;
                width: 1.6rem;
                height: auto;
            }
        }

        .name {
            width: 100%;
            font-family: "CHEDROS", sans-serif;
            font-weight: bold;
            color: white;
            font-size: 1.4rem;
            position: relative;
            top: 2px;
        }

        .toggle {
            svg {
                fill: white;
                width: 1.3rem;
                height: auto;
            }
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-top: 8px;
    }
}
