/* Colors
-------------------------------------------------- */
$primary: #5078df;
$primary-hover: #4162b6;
$primary2: #3c5297;
$primary-dark: #284a8b;
$dark: #080d26;
$background: #e9eef2;
$red: #e26e4f;
$green: #2d9565;
$grey: #eaeef2;

/* Spacing
-------------------------------------------------- */
$layout_padding: 25px 37px;
$desktop_min_width: 800px;

/* Design
-------------------------------------------------- */
$radius: 20px;

$nav-header: 20;
$popup-z-index: 50;

$mobile_nav_height: 75px + 25px + 25px;
