@import "./../../../assets/sass/var.scss";

.item-or-penguin {
    border-radius: 8px;
    overflow: hidden;
    background-color: white;
    position: relative;
    cursor: pointer;

    .count {
        position: absolute;
        top: 8px;
        right: 8px;
        color: white;
        background-color: $primary;
        border-radius: 99px;
        width: 1.8rem;
        height: 1.8rem;
        display: flex;
        justify-content: center;
        align-items: center;

        font-family: "Config Rounded", sans-serif;
        font-size: 1.1rem;
        font-weight: normal;

        z-index: 1;
    }

    .thumbnail {
        width: 100%;
        position: relative;

        &::after {
            content: "";
            display: block;
            padding-bottom: 100%;
        }

        img {
            position: absolute;
            // top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            max-width: 100%;
        }
    }

    .info {
        padding: 5px 10px;

        .name {
            font-family: "CHEDROS", sans-serif;
            font-weight: bold;
            font-size: 1.2rem;
        }

        .price {
            font-family: "Config Rounded", sans-serif;
            opacity: 0.6;

            .price {
                font-weight: bold;
            }
        }
    }
}

// desktop
@media (min-width: $desktop_min_width) {
    .item-or-penguin {
        min-width: 250px;
        width: 0;
    }
}
