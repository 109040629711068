@import "assets/sass/var.scss";

.item {
    background-color: white;
    border-radius: 8px;
    height: 5.9rem;

    display: flex;
    align-items: center;
    justify-content: space-between;

    .infos {
        margin: 1rem;
        color: $primary;
        flex-grow: 1;

        .name {
            font-family: "CHEDROS", sans-serif;
            font-size: 1.3rem;
            font-weight: bold;
            white-space: nowrap;
        }

        .subProperty {
            font-family: "Config Rounded", sans-serif;
            color: gray;
        }

        .rarity {
            margin-top: 0.6rem;
            font-family: "CHEDROS", sans-serif;
            font-weight: bold;
        }
    }

    .thumbnail {
        .img {
            height: 5.9rem;
            border-radius: 8px;
            aspect-ratio: 1/1;
        }
    }
}
