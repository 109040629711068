@import "assets/sass/var.scss";

.item {
    position: relative;
    border-radius: 10px;
    overflow: hidden;

    .thumbnail {
        width: 100%;
        z-index: 0;
        aspect-ratio: 1/1;
    }

    .name {
        position: absolute;
        bottom: 6%;
        left: 10%;
        right: 0;
        color: white;
        font-family: "CHEDROS", sans-serif;
        font-weight: bold;
        font-size: 1.4rem;
        z-index: 2;
    }

    .count {
        position: absolute;
        top: 8px;
        right: 8px;
        color: white;
        background-color: $primary;
        border-radius: 99px;
        width: 1.8rem;
        height: 1.8rem;
        display: flex;
        justify-content: center;
        align-items: center;

        font-family: "Config Rounded", sans-serif;
        font-size: 1.1rem;
        font-weight: normal;
    }

    &:hover {
        cursor: pointer;
    }

    &.loaded::after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 30%;
        background: linear-gradient(to top, #080d26ff 0%, #080d2600 100%);
        z-index: 1;
    }
}
