@import "assets/sass/var.scss";

#mobile-header {
    // display: flex;
    // justify-content: space-between;
    // align-items: flex-start;
    padding: 60px 30px 30px 30px;

    .icon {
        height: 25px;
    }

    .titles {
        text-align: center;

        h1 {
            font-family: "CHEDROS", sans-serif;
            font-weight: bold;
            position: relative;
            top: -0.1em;
            font-size: 2rem;
        }

        h2 {
            font-family: "Config Rounded", sans-serif;
            font-weight: medium;
            position: relative;
            top: -0.2em;
            font-size: 1.4rem;
        }
    }

    &.transparent {
        padding-bottom: 80px;
        color: white;

        .icon {
            fill: white;
        }
    }

    &.light {
        padding-bottom: 30px;
        background-color: $background;
        color: $dark;

        .icon {
            fill: $dark;
        }
    }
}

// desktop
@media (min-width: $desktop_min_width) {
    #mobile-header {
        display: none;
    }
}
