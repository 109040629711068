@import "assets/sass/theme.scss";

.badgesContainer {
    gap: 3px;
    display: flex;
    flex-direction: row;
    overflow: auto;

    @extend .custom-scrollbar;
}

.popup {
    align-items: flex-start !important;
    $padding: 40px;
    padding-left: $padding !important;
    padding-right: $padding !important;
    padding-top: 20px !important;
    overflow: auto;

    @extend .custom-scrollbar;

    &::-webkit-scrollbar-track {
        margin: 20px;
    }

    button {
        flex-shrink: 0;
    }
}

.filtersContainer {
    width: 100%;

    .filter {
        width: 100%;

        &:last-child {
            hr {
                display: none;
            }
        }
    }
}
