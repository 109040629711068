.locked-content {
    position: absolute;

    background-color: rgba(43, 43, 43, 0.8);
    width: 100%;
    height: 100%;

    z-index: 500;

    border-radius: 10px !important;

    h2 {
        font-size: min(100%, 28px);
    }

    p {
        font-size: min(100%, 14px);
    }

    > div {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        padding: 10px;
        text-align: center;

        color: white;
    }
}
