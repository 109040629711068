/* Import font
-------------------------------------------------- */
// CHEDROS
@font-face {
  font-family: "CHEDROS";
  src: url("./../fonts/CHEDROS\ Regular.ttf");
}

// Config Rounded
@font-face {
  font-family: "Config Rounded";
  src: url("./../fonts/ConfigRoundedBoldItalic.eot");
  src: local("Config Rounded Bold Italic"), local("ConfigRoundedBoldItalic"),
    url("./../fonts/ConfigRoundedBoldItalic.eot?#iefix") format("embedded-opentype"),
    url("./../fonts/ConfigRoundedBoldItalic.woff2") format("woff2"),
    url("./../fonts/ConfigRoundedBoldItalic.woff") format("woff"),
    url("./../fonts/ConfigRoundedBoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Config Rounded";
  src: url("./../fonts/ConfigRoundedBold.eot");
  src: local("Config Rounded Bold"), local("ConfigRoundedBold"),
    url("./../fonts/ConfigRoundedBold.eot?#iefix") format("embedded-opentype"),
    url("./../fonts/ConfigRoundedBold.woff2") format("woff2"), url("./../fonts/ConfigRoundedBold.woff") format("woff"),
    url("./../fonts/ConfigRoundedBold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Config Rounded Extralight Itali";
  src: url("./../fonts/ConfigRoundedExtralightItalic.eot");
  src: local("Config Rounded Extralight Italic"), local("ConfigRoundedExtralightItalic"),
    url("./../fonts/ConfigRoundedExtralightItalic.eot?#iefix") format("embedded-opentype"),
    url("./../fonts/ConfigRoundedExtralightItalic.woff2") format("woff2"),
    url("./../fonts/ConfigRoundedExtralightItalic.woff") format("woff"),
    url("./../fonts/ConfigRoundedExtralightItalic.ttf") format("truetype");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Config Rounded";
  src: url("./../fonts/ConfigRoundedExtrabold.eot");
  src: local("Config Rounded Extrabold"), local("ConfigRoundedExtrabold"),
    url("./../fonts/ConfigRoundedExtrabold.eot?#iefix") format("embedded-opentype"),
    url("./../fonts/ConfigRoundedExtrabold.woff2") format("woff2"),
    url("./../fonts/ConfigRoundedExtrabold.woff") format("woff"),
    url("./../fonts/ConfigRoundedExtrabold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Config Rounded";
  src: url("./../fonts/ConfigRoundedExtraboldItalic.eot");
  src: local("Config Rounded Extrabold Italic"), local("ConfigRoundedExtraboldItalic"),
    url("./../fonts/ConfigRoundedExtraboldItalic.eot?#iefix") format("embedded-opentype"),
    url("./../fonts/ConfigRoundedExtraboldItalic.woff2") format("woff2"),
    url("./../fonts/ConfigRoundedExtraboldItalic.woff") format("woff"),
    url("./../fonts/ConfigRoundedExtraboldItalic.ttf") format("truetype");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "Config Rounded";
  src: url("./../fonts/ConfigRoundedBlack.eot");
  src: local("Config Rounded Black"), local("ConfigRoundedBlack"),
    url("./../fonts/ConfigRoundedBlack.eot?#iefix") format("embedded-opentype"),
    url("./../fonts/ConfigRoundedBlack.woff2") format("woff2"), url("./../fonts/ConfigRoundedBlack.woff") format("woff"),
    url("./../fonts/ConfigRoundedBlack.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Config Rounded";
  src: url("./../fonts/ConfigRoundedBlackItalic.eot");
  src: local("Config Rounded Black Italic"), local("ConfigRoundedBlackItalic"),
    url("./../fonts/ConfigRoundedBlackItalic.eot?#iefix") format("embedded-opentype"),
    url("./../fonts/ConfigRoundedBlackItalic.woff2") format("woff2"),
    url("./../fonts/ConfigRoundedBlackItalic.woff") format("woff"),
    url("./../fonts/ConfigRoundedBlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Config Rounded";
  src: url("./../fonts/ConfigRoundedLightItalic.eot");
  src: local("Config Rounded Light Italic"), local("ConfigRoundedLightItalic"),
    url("./../fonts/ConfigRoundedLightItalic.eot?#iefix") format("embedded-opentype"),
    url("./../fonts/ConfigRoundedLightItalic.woff2") format("woff2"),
    url("./../fonts/ConfigRoundedLightItalic.woff") format("woff"),
    url("./../fonts/ConfigRoundedLightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Config Rounded";
  src: url("./../fonts/ConfigRoundedLight.eot");
  src: local("Config Rounded Light"), local("ConfigRoundedLight"),
    url("./../fonts/ConfigRoundedLight.eot?#iefix") format("embedded-opentype"),
    url("./../fonts/ConfigRoundedLight.woff2") format("woff2"), url("./../fonts/ConfigRoundedLight.woff") format("woff"),
    url("./../fonts/ConfigRoundedLight.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Config Rounded";
  src: url("./../fonts/ConfigRoundedMedium.eot");
  src: local("Config Rounded Medium"), local("ConfigRoundedMedium"),
    url("./../fonts/ConfigRoundedMedium.eot?#iefix") format("embedded-opentype"),
    url("./../fonts/ConfigRoundedMedium.woff2") format("woff2"),
    url("./../fonts/ConfigRoundedMedium.woff") format("woff"),
    url("./../fonts/ConfigRoundedMedium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Config Rounded";
  src: url("./../fonts/ConfigRoundedMediumItalic.eot");
  src: local("Config Rounded Medium Italic"), local("ConfigRoundedMediumItalic"),
    url("./../fonts/ConfigRoundedMediumItalic.eot?#iefix") format("embedded-opentype"),
    url("./../fonts/ConfigRoundedMediumItalic.woff2") format("woff2"),
    url("./../fonts/ConfigRoundedMediumItalic.woff") format("woff"),
    url("./../fonts/ConfigRoundedMediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Config Rounded";
  src: url("./../fonts/ConfigRoundedExtralight.eot");
  src: local("Config Rounded Extralight"), local("ConfigRoundedExtralight"),
    url("./../fonts/ConfigRoundedExtralight.eot?#iefix") format("embedded-opentype"),
    url("./../fonts/ConfigRoundedExtralight.woff2") format("woff2"),
    url("./../fonts/ConfigRoundedExtralight.woff") format("woff"),
    url("./../fonts/ConfigRoundedExtralight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Config Rounded";
  src: url("./../fonts/ConfigRoundedItalic.eot");
  src: local("Config Rounded Italic"), local("ConfigRoundedItalic"),
    url("./../fonts/ConfigRoundedItalic.eot?#iefix") format("embedded-opentype"),
    url("./../fonts/ConfigRoundedItalic.woff2") format("woff2"),
    url("./../fonts/ConfigRoundedItalic.woff") format("woff"),
    url("./../fonts/ConfigRoundedItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Config Rounded";
  src: url("./../fonts/ConfigRoundedRegular.eot");
  src: local("Config Rounded Regular"), local("ConfigRoundedRegular"),
    url("./../fonts/ConfigRoundedRegular.eot?#iefix") format("embedded-opentype"),
    url("./../fonts/ConfigRoundedRegular.woff2") format("woff2"),
    url("./../fonts/ConfigRoundedRegular.woff") format("woff"),
    url("./../fonts/ConfigRoundedRegular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Config Rounded";
  src: url("./../fonts/ConfigRoundedThin.eot");
  src: local("Config Rounded Thin"), local("ConfigRoundedThin"),
    url("./../fonts/ConfigRoundedThin.eot?#iefix") format("embedded-opentype"),
    url("./../fonts/ConfigRoundedThin.woff2") format("woff2"), url("./../fonts/ConfigRoundedThin.woff") format("woff"),
    url("./../fonts/ConfigRoundedThin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Config Rounded";
  src: url("./../fonts/ConfigRoundedThinItalic.eot");
  src: local("Config Rounded Thin Italic"), local("ConfigRoundedThinItalic"),
    url("./../fonts/ConfigRoundedThinItalic.eot?#iefix") format("embedded-opentype"),
    url("./../fonts/ConfigRoundedThinItalic.woff2") format("woff2"),
    url("./../fonts/ConfigRoundedThinItalic.woff") format("woff"),
    url("./../fonts/ConfigRoundedThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Config Rounded Text";
  src: url("./../fonts/ConfigRoundedText.eot");
  src: local("Config Rounded Text"), local("ConfigRoundedText"),
    url("./../fonts/ConfigRoundedText.eot?#iefix") format("embedded-opentype"),
    url("./../fonts/ConfigRoundedText.woff2") format("woff2"), url("./../fonts/ConfigRoundedText.woff") format("woff"),
    url("./../fonts/ConfigRoundedText.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Config Rounded Text";
  src: url("./../fonts/ConfigRoundedTextItalic.eot");
  src: local("Config Rounded Text Italic"), local("ConfigRoundedTextItalic"),
    url("./../fonts/ConfigRoundedTextItalic.eot?#iefix") format("embedded-opentype"),
    url("./../fonts/ConfigRoundedTextItalic.woff2") format("woff2"),
    url("./../fonts/ConfigRoundedTextItalic.woff") format("woff"),
    url("./../fonts/ConfigRoundedTextItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Config Rounded";
  src: url("./../fonts/ConfigRoundedSemibold.eot");
  src: local("Config Rounded Semibold"), local("ConfigRoundedSemibold"),
    url("./../fonts/ConfigRoundedSemibold.eot?#iefix") format("embedded-opentype"),
    url("./../fonts/ConfigRoundedSemibold.woff2") format("woff2"),
    url("./../fonts/ConfigRoundedSemibold.woff") format("woff"),
    url("./../fonts/ConfigRoundedSemibold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Config Rounded";
  src: url("./../fonts/ConfigRoundedSemiboldItalic.eot");
  src: local("Config Rounded Semibold Italic"), local("ConfigRoundedSemiboldItalic"),
    url("./../fonts/ConfigRoundedSemiboldItalic.eot?#iefix") format("embedded-opentype"),
    url("./../fonts/ConfigRoundedSemiboldItalic.woff2") format("woff2"),
    url("./../fonts/ConfigRoundedSemiboldItalic.woff") format("woff"),
    url("./../fonts/ConfigRoundedSemiboldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}

/* Bootstrap overrides
-------------------------------------------------- */

@import "./var.scss";

/* Do not move the imports from here.
Override Bootstrap variables only above.
-------------------------------------------------- */

// Bootstrap

@import "~bootstrap/scss/bootstrap.scss";

@import "~bootstrap/scss/reboot";

.custom-classname.btn-primary {
  background-color: green;
}

/* Base
-------------------------------------------------- */
html {
  min-height: 100%;
  font-size: 14px;
}

body {
  background-color: $background;
  line-height: 1;

  &.background-image {
    background-image: url("./../../assets/img/main-background.jpg");
    background-size: cover;
    background-attachment: fixed;
  }

  &.no-scroll {
    overflow: hidden;
  }

  &.no-footer {
    #root {
      footer {
        display: none;
      }
    }
  }
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

.wrapper {
  min-height: 100vh;
}

.opacity-6 {
  opacity: 0.6;
}

.dapp-icon {
  padding: 5px;
  border-radius: 50%;
  background-color: $light;
  width: 65px;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;

  &.icon-medium {
    width: 80px;
    height: 80px;
  }
}

/* Dashboard
-------------------------------------------------- */
.action-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $white;
  margin: 0 7px;

  button {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background-color: $white;

    &:hover {
      opacity: 0.9;
    }
  }
}

.transactions {
  .transaction-icon {
    border-radius: 50%;
    width: 40px;
    height: 40px;
  }

  &.table {
    .transaction-hash .trim {
      @include media-breakpoint-down(xl) {
        max-width: none;
      }

      @include media-breakpoint-down(lg) {
        max-width: 26rem;
      }

      @include media-breakpoint-down(md) {
        max-width: 12rem;
      }

      @include media-breakpoint-down(sm) {
        max-width: 8rem;
      }
    }

    td,
    tr {
      padding: 0.65rem;
    }

    td {
      white-space: nowrap;
      vertical-align: middle;
    }

    tr:last-of-type {
      td {
        border-bottom: 1px solid #dee2e6;
      }
    }
  }
}

/* Actions
-------------------------------------------------- */

.not-allwed {
  cursor: not-allowed;
}

/* Loader, Transaction success/Fail
-------------------------------------------------- */
.page-state {
  .lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
  }

  .lds-ellipsis div {
    position: absolute;
    top: 27px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.1);
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }

  .bg-blue .lds-ellipsis div {
    background: rgba(255, 255, 255, 0.4);
  }

  .lds-ellipsis div:nth-child(1) {
    left: 6px;
    animation: lds-ellipsis1 0.6s infinite;
  }

  .lds-ellipsis div:nth-child(2) {
    left: 6px;
    animation: lds-ellipsis2 0.6s infinite;
  }

  .lds-ellipsis div:nth-child(3) {
    left: 26px;
    animation: lds-ellipsis2 0.6s infinite;
  }

  .lds-ellipsis div:nth-child(4) {
    left: 45px;
    animation: lds-ellipsis3 0.6s infinite;
  }

  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }

    100% {
      transform: scale(1);
    }
  }

  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }

    100% {
      transform: scale(0);
    }
  }

  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }

    100% {
      transform: translate(19px, 0);
    }
  }
}

/* scroll bar
-------------------------------------------------- */
.custom-scrollbar {
  /* width */
  &::-webkit-scrollbar {
    border-radius: 99999px;
    height: 10px;
    width: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    border-radius: 99999px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $primary;
    border-radius: 99999px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $primary-dark;
  }
}
