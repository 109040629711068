@import "assets/sass/var.scss";

.customize {
    margin-bottom: $mobile_nav_height;

    .content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 25px;
        gap: 20px;

        .items {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 24px;

            .item {
                border-radius: 999px;
                background-color: white;
                width: 43px;
                height: 43px;
                position: relative;

                background-position: center;
                background-repeat: no-repeat;
                background-size: 70%;
                cursor: pointer;

                > img {
                    max-width: 100%;
                    border-radius: 9999px;
                }

                &.filled {
                    background-color: transparent;
                }

                &::before {
                    content: "";
                    position: absolute;
                    $diff: 7px;
                    top: -$diff;
                    left: -$diff;
                    right: -$diff;
                    bottom: -$diff;
                    background-color: transparent;
                    border-radius: 999px;
                    border: 3px solid $primary;
                }

                &.modified::before {
                    border: 3px solid $green;
                }

                &.hat {
                    background-image: url("/assets/img/icons/items/unicolor_blue/hat.svg");
                }

                &.eyes {
                    background-image: url("/assets/img/icons/items/unicolor_blue/eyes.svg");
                }

                &.clothes {
                    background-image: url("/assets/img/icons/items/unicolor_blue/clothes.svg");
                }

                &.beak {
                    background-image: url("/assets/img/icons/items/unicolor_blue/beak.svg");
                }

                &.skin {
                    background-image: url("/assets/img/icons/items/unicolor_blue/skin.svg");
                    background-size: 90%;
                    background-position: center bottom;
                }

                &.weapon {
                    background-image: url("/assets/img/icons/items/unicolor_blue/weapon.svg");
                    transform: rotate(20deg);
                    background-size: 60%;
                }

                &.background {
                    background-image: url("/assets/img/icons/items/unicolor_blue/background.svg");
                }
            }
        }
    }

    .utilsBtn {
        margin-top: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
    }
}

// desktop
@media (min-width: $desktop_min_width) {
    body {
        background-position: center;
    }

    #body-content {
        display: grid;
        grid-template-areas:
            "popup head"
            "popup customize";
        grid-template-columns: 4fr 6fr;
        grid-gap: 40px;
        padding: 0 40px;

        min-height: calc(100vh - 109px);

        padding-top: 120px;
        padding-bottom: 60px;
    }

    .customize {
        grid-area: customize;
        margin-bottom: 0;

        .content {
            justify-content: center;
            gap: 50px;

            .items {
                .item {
                    width: 60px;
                    height: 60px;
                }
            }
        }
    }
}
