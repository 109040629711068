@import "./../../../assets/sass/var.scss";

.popup {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    background-color: rgba(8, 13, 38, 0.8);

    &.open {
    }

    &.close {
        display: none;
    }

    .close {
        background-color: #5078df;
        border-radius: 999px;
        height: 2.8rem;
        width: 2.8rem;
        min-width: 2.8rem;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        svg {
            fill: white;
            width: 0.9rem;
            height: auto;
        }
    }

    > .close {
        margin: 36px 45px 15px auto;
    }

    .content {
        background-color: $primary;
        height: calc(100% - 90px);
        border-radius: 50px 50px 0 0;
        padding: 25px 0;
        position: relative;

        > header {
            margin: 0 50px;
            position: relative;

            .close {
                display: none;
                position: absolute;
                top: -5px;
                right: -20px;
                border: white solid 1px;
            }

            h2 {
                font-family: "CHEDROS", sans-serif;
                font-weight: bold;
                color: white;
                margin-bottom: 10px;
            }

            .search-bar {
                background-color: $background;
                border-radius: 999px;
                padding: 10px 20px;
                display: flex;
                align-items: center;
                gap: 10px;

                svg {
                    height: 1.4rem;
                    fill: $primary;
                }

                input {
                    background-color: transparent;
                    border: none;
                    width: 100%;
                    font-family: "Config Rounded", sans-serif;

                    &::placeholder {
                        font-family: "Config Rounded", sans-serif;
                        font-style: italic;
                        color: rgba(8, 13, 38, 0.2);
                        font-size: 1.1rem;
                    }
                }
            }
        }

        .list {
            max-height: calc(100% - 130px);
            overflow: auto;
        }

        .control {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 40px;
            gap: 15px;
            position: absolute;
            bottom: 40px;
            left: 0;
            right: 0;

            &::before {
                content: "";
                position: absolute;
                bottom: 100%;
                left: -40px;
                right: -40px;
                height: 38px;
                background: linear-gradient(to top, rgba(80, 120, 223, 1) 0%, rgba(80, 120, 223, 0) 100%);
                pointer-events: none;
            }

            button {
                white-space: nowrap;
                background-color: transparent;
                border: white 2px solid;
                border-radius: 999px;
                color: white;
                font-family: "CHEDROS", sans-serif;
                font-weight: bold;
                font-size: 1.5rem;
                cursor: pointer;
                padding: 4px 10px 2px 10px;
                flex: 1;

                &.filled {
                    flex: 2;
                    background-color: white;
                    color: $primary;
                }
            }
        }
    }
}

// desktop
@media (min-width: $desktop_min_width) {
    .popup {
        > .close {
            display: none;
        }

        .content {
            max-width: 500px;
            margin: auto;
            border-radius: 20px;
            margin-top: 45px;

            > header {
                .close {
                    display: flex;
                }
            }
        }
    }
}
