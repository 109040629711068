@import "./../../../assets/sass/var.scss";

footer#main-footer {
    padding-top: 90px;

    .social {
        display: flex;
        gap: 10px;
    }

    .footer {
        background-color: white;
        border-radius: 60px 60px 0 0;
        position: relative;
        top: -60px;
        padding: 40px 40px 60px 40px;

        &::after {
            content: "";
            position: absolute;
            height: 120px;
            background-color: white;
            left: 0;
            right: 0;
        }

        > * {
            max-width: 1050px;
            margin: 0 auto;
        }

        .main {
            padding: 25px 10px;
            margin-bottom: 20px;
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: auto;
            grid-template-areas: "logo_social" "terms" "web_by";
            justify-items: center;
            grid-gap: 30px;

            .center {
                grid-area: logo_social;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 30px;
                padding-bottom: 30px;
                width: 100%;
                border-bottom: 1px solid #080d2680;

                .social {
                    justify-content: center;
                    grid-area: social;
                }
            }

            .webdesign-by {
                grid-area: web_by;
                display: flex;
                gap: 4px;
                align-items: center;

                span {
                    color: #3d3d3d;
                    font-family: "Config Rounded", sans-serif;
                    font-size: 1rem;
                    font-weight: bold;
                    text-transform: uppercase;
                }

                svg {
                    height: 0.6rem;
                    fill: #242424;
                }
            }

            .privacy-policy {
                grid-area: terms;
                display: flex;
                gap: 15px;
                width: auto;

                a {
                    color: rgba(36, 36, 36, 0.5);
                    font-family: "Config Rounded", sans-serif;
                    font-size: 1.1rem;
                    font-weight: 600;
                }
            }
        }
    }
}

@media (min-width: $desktop_min_width) {
    footer#main-footer {
        .footer {
            padding: 0 60px;

            &::after {
                height: 60px;
            }

            .main {
                display: flex;
                align-items: center;
                position: relative;
                top: 30px;
                z-index: 1;
                margin: auto;

                .center {
                    flex-direction: row;
                    border: none;
                    padding-bottom: 0;
                }

                .webdesign-by {
                    flex-direction: column;
                }

                .privacy-policy {
                    flex-direction: row;
                    align-items: unset;

                    a {
                        white-space: nowrap;
                    }
                }
            }
        }
    }
}
