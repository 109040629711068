@import "./../../../assets/sass/var.scss";

/*
.buy {
    display: flex;
    justify-content: center;
    margin-top: 30px;

    h2,
    .price,
    .desktop {
        display: none;
    }
}

// desktop
@media (min-width: $desktop_min_width) {*/
.buy {
    margin-top: 40px;
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    justify-self: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 60%;

    h2,
    .price,
    .desktop {
        display: block;
    }

    .mobile {
        display: none;
    }

    h2 {
        font-family: "CHEDROS", sans-serif;
        font-size: 1.8rem;
        font-weight: bold;
    }

    .price {
        font-family: "Config Rounded", sans-serif;
        font-size: 1.6rem;
        font-weight: bold;
        color: $primary;
    }

    .button {
        margin-top: 20px;
        min-width: 70%;
    }

    .seeOffers {
        color: gray;

        &:hover {
            cursor: pointer;
        }
    }
}

/*}*/
