@import "./../../../../assets/sass/var.scss";

.popup {
    display: none;
    position: fixed;
    z-index: 500;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #080d26b4;

    justify-content: center;
    flex-direction: column;
    align-items: stretch;

    padding: 30px;

    &.visible {
        display: flex;
    }

    .content {
        background-color: white;
        border-radius: 20px;

        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 15px;

        padding: 20px;
        padding-top: 60px;
        position: relative;

        max-width: unset;
        max-height: unset;

        .close {
            background-color: $primary;
            position: absolute;
            top: 15px;
            right: 20px;
            z-index: 1;
            height: 38px;
            width: 38px;
            border-radius: 999px;
            display: flex;
            justify-content: center;
            align-items: center;

            &:hover {
                cursor: pointer;
                background-color: darken($primary, 10%);
            }

            .icon {
                fill: white;
                height: 14px;
            }
        }

        .top-icon {
            width: 140px;
            position: absolute;
            top: -90px;

            > img,
            > svg {
                width: 100%;
            }

            > svg {
                position: relative;
                top: 50px;
            }
        }
    }
}

// desktop
@media (min-width: $desktop_min_width) {
    .popup {
        align-items: center;

        .content {
            min-width: 350px;
            max-width: 65vw;
            max-height: 96vh;
        }
    }
}
