@import "./../../../assets/sass/var.scss";

.market-data {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    max-width: 430px;
    margin: auto;

    > .item {
        background: white;
        border-radius: 6px;
        padding: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 6px;

        .value {
            font-family: "Config Rounded", sans-serif;
            font-weight: 500;
            font-size: 1.3rem;
        }

        .label {
            font-family: "Config Rounded", sans-serif;
            font-weight: 500;
            font-size: 1.1rem;
            color: #080d2680;
        }
    }
}

// desktop
@media (min-width: $desktop_min_width) {
    .market-data {
        max-width: 860px;
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}
