@import "assets/sass/var.scss";
@import "assets/sass/theme.scss";

.all-items {
    padding: 15px;
    width: 100%;
    padding-bottom: 120px;

    &.has-filter {
        top: 200px;
    }

    > h2 {
        color: #080d26;
        font-family: "CHEDROS", sans-serif;
        font-weight: bold;
    }

    > .content {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 10px;
        overflow: auto;
        height: 100%;

        @extend .custom-scrollbar;

        .item-wrapper {
            flex-basis: calc(50% - 5px);
        }
    }
}

// desktop
@media (min-width: $desktop_min_width) {
    .all-items {
        display: flex;
        flex-direction: column;
        position: relative;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        padding-bottom: 0;

        &.has-filter {
            top: 0;
        }

        > h2 {
            display: block;
            width: 100%;
        }

        > .content {
            position: relative;
            top: 0;
            margin-top: 15px;
            gap: 15px;

            .item-wrapper {
                flex-basis: auto;
                width: 250px;
                height: 250px;
            }
        }
    }
}
