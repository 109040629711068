.collapseHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;

    &:hover {
        cursor: pointer;
        background-color: #ebeef6;
        text-decoration: none;
        border-radius: 9999px;
    }
}

.collapseContainer {
    padding: 10px;
    background-color: #f5f7fa;
    border-radius: 8px;

    display: flex;
    flex-direction: column;
    gap: 4px;
}
