@import "./../../assets/sass/var.scss";
@import "./../../assets/sass/theme.scss";

#launchpad {
    background-color: $background;

    .top-page {
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: $layout_padding;
        gap: 120px;

        background-image: url("./../../assets/img/launchpad_hero_page.jpg");
        background-size: cover;
        background-position: calc(50% - 13px) center;
        // background-attachment: fixed;

        img {
            height: 52px;
        }

        .info {
            h1 {
                font-family: "Config Rounded", sans-serif;
                font-weight: 400;
                color: white;
                text-align: center;
                line-height: 1;
                text-align: center;
                text-transform: none;
                font-size: 2rem;
                line-height: 120%;
                // opacity: 0.83;
                margin-bottom: 10px;
            }
            button {
                margin: auto;
            }
        }
        &::after {
            content: "";
            background: linear-gradient(to top, #000000 0%, #000000d9 40%, transparent 100%);
            width: 100vw;
            height: 12vh;
            position: absolute;
            bottom: 0;
            z-index: 1;
        }
    }

    .focuses {
        background-color: #000;
        padding: $layout_padding;
        color: $grey;
        font-size: 1.5rem;
        text-align: center;
        line-height: 120%;
        padding-top: 90px;
        p {
            margin: 40px 0;
        }
        .info {
            display: flex;
            flex-direction: column;
            column-gap: 10px;
            row-gap: 25px;
            div {
                padding: 30px;
                & {
                    border-radius: 20px;
                }
                &:first-child {
                    background-color: $primary2;
                }
                &:last-child {
                    background-color: $grey;
                    color: black;
                }
            }
        }
    }

    .advantages {
        counter-reset: advantages;
        background-color: #000;
        padding: $layout_padding;
        color: $grey;
        font-size: 1.5rem;
        text-align: center;
        line-height: 130%;
        padding-top: 90px;
        > p {
            margin: 40px 0;
            font-size: 1.8rem;
            line-height: 150%;
        }
    }

    .submit {
        position: relative;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: $layout_padding;
        gap: 120px;
        background-image: url("./../../assets/img/submit_illustration.jpg");
        background-size: 351%;
        background-position: calc(50% - 3px) center;
        // background-attachment: fixed;
        p {
            font-family: "Config Rounded", sans-serif;
            font-weight: 400;
            color: white;
            text-align: center;
            line-height: 1;
            text-align: center;
            text-transform: none;
            font-size: 4.4rem;
            line-height: 120%;
            margin-top: 71px;
        }
        button {
            margin-bottom: 10px;
        }
    }

    .contact {
        background-color: black;
        padding: $layout_padding;
        padding-top: 150px;
        padding-bottom: 110px;
    }
}

// desktop
@media (min-width: $desktop_min_width) {
    #launchpad {
        .top-page {
            position: relative;
            padding-top: 100px;
            background-image: url("./../../assets/img/launchpad_hero_page.jpg");
            // background-attachment: fixed;
            // background-position: calc(50vh + 77vw) center;
            background-size: cover;
            background-position-x: center;
            background-position-y: center;
            min-height: 100vh;
            text-align: center;
            img {
                opacity: 0;
            }

            .info {
                margin-top: 20px;
                h1 {
                    font-size: 5rem;
                    line-height: 97%;
                }
            }
            &::after {
                width: 100%;
            }
        }
        .focuses {
            .info {
                flex-direction: row;
                justify-content: center;
                column-gap: 50px;
                div {
                    max-width: 400px;
                }
            }
        }
        .advantages {
            > p {
                font-size: 3.6rem;
                line-height: 130%;
            }
        }
        .submit {
            background-size: 100%;
            p {
                margin-top: 20px;
            }
            button {
                margin-bottom: 30px;
                padding: 20px 30px;
            }
        }
    }
}
