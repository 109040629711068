@import "./../../../assets/sass/var.scss";

.elmt {
    font-family: "CHEDROS", sans-serif;
    font-weight: bold;
    font-size: 1.4rem;
    color: #080d2680;
    cursor: pointer;
    display: inline-block;

    &.active {
        color: #080d26;

        &::after {
            content: "";
            display: block;
            width: 110%;
            position: relative;
            left: -5%;
            height: 0.3rem;
            background-color: $primary;
            border-radius: 0.3rem 0.3rem 0 0;
        }
    }
}
