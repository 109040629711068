@import "./../../../assets/sass/var.scss";

.button {
    border: 1px solid $primary;
    background-color: white;
    color: $primary;
    padding: 0 12px;
    height: 2.2rem;
    border-radius: 999px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 16px;

    &.normal {
        border: 1px solid $primary;
        background-color: white;
        color: $primary;
    }

    &.primary {
        border: 1px solid $primary;
        background-color: $primary;
        color: white;
    }

    &.primary-outline {
        border: 2px solid white;
        background-color: $primary;
        color: white;
    }


    &.cancel {
        border: 1px solid $red;
        background-color: $red;
        color: white;
    }

    &.cancel-outline {
        border: 1px solid $red;
        background-color: white;
        color: $red;
    }

    &:disabled {
        filter: grayscale(1);
    }

    .text {
        font-family: "CHEDROS", sans-serif;
        font-size: 1.2rem;
        font-weight: bold;
        line-height: 0.6;
        position: relative;
        top: 0.15rem;
        letter-spacing: 0.05rem;
    }

    .text:only-child {
        margin: 0 14px;
    }

    .icon {
        svg {
            height: 1.2rem;
            fill: $primary;
            stroke: $primary;
        }
    }
}
