@import "./../../../assets/sass/var.scss";

.navbar {
    background-color: white;
    border-radius: 9999px;
    padding: 25px 40px;
    position: fixed;
    bottom: 25px;
    left: 50%;
    transform: translateX(-50%);

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 30px;

    z-index: 40;

    .navItem {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;

        .icon {
            fill: $primary;
            width: 25px;
            max-height: 25px;
        }

        &.marketIcon {
            .icon {
                max-height: 26px;
            }
        }

        &.labIcon {
            .icon {
                max-height: 23px;
            }
        }

        p {
            display: none;

            text-align: center;
            color: $dark;
            font-family: "CHEDROS", sans-serif;
            position: absolute;
            top: 15px;
            margin-top: 10px;
            font-weight: bold;
        }

        p:before {
            content: "";
            display: inline-block;
            height: 6px;
            width: 6px;
            border-radius: 10px;
            background-color: $primary;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: -9px;
        }

        &.active {
            .icon {
                position: relative;
                top: -12px;
            }

            p {
                display: inline-block;
            }
        }
    }
}

#desktop-header {
    display: none;
    padding: 15px 40px;

    header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: white;
        border-radius: 9999px;
        padding: 15px 45px;
        gap: 25px;

        .left {
            display: flex;
            align-items: center;
            gap: 40px;

            > img {
                height: 40px;
                cursor: pointer;
            }

            nav {
                position: relative;
                bottom: -0.2rem;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 0px;
                height: 100%;

                .item {
                    font-family: "CHEDROS", sans-serif;
                    font-size: 1.3rem;
                    font-weight: bold;
                    color: $dark;
                    white-space: nowrap;
                    position: relative;

                    padding: 5px 15px;
                    padding-bottom: 1px; // without this, the text is not vertically centered when hovering
                    text-align: center;
                    line-height: 20px;

                    .itemName {
                        height: fit-content;
                        width: fit-content;
                    }

                    .nestedDropdown {
                        display: none;
                        position: absolute;
                        left: 50%;

                        z-index: $popup-z-index;

                        padding-top: 10px;
                        transform: translateX(-50%);

                        .container {
                            background-color: white;
                            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
                            border-radius: 6px;

                            .underline {
                                color: #080d26;
                                width: 100%;

                                &::after {
                                    content: "";
                                    display: block;

                                    height: 0.3rem;
                                    background-color: $primary;
                                    border-radius: 0.3rem 0.3rem 0 0;
                                }
                            }

                            .flex {
                                display: flex;
                                flex-direction: column;
                                gap: 10px;

                                padding: 15px 15px;
                            }
                        }
                    }

                    &:hover {
                        cursor: pointer;
                        background-color: #ebeef6;
                        text-decoration: none;
                        border-radius: 9999px;

                        .nestedDropdown {
                            display: block;
                        }
                    }
                }
            }
        }

        .center {
            width: 40%;

            .search {
                background: #e9eef2;
                border-radius: 999px;
                padding: 10px 18px;
                display: flex;
                align-items: center;
                gap: 8px;

                .icon {
                    height: 1rem;
                }

                input {
                    border: none;
                    background-color: transparent;
                    font-family: "Config Rounded", sans-serif;
                    font-weight: 300;
                    width: 100%;
                }
            }
        }

        .right {
            display: flex;
            align-items: center;
            gap: 15px;

            .social {
                display: flex;
                gap: 10px;
            }

            .profile {
                overflow: hidden;
                border-radius: 9999px;
                border: $primary 2px solid;

                img {
                    height: 45px;
                    width: 45px;
                    object-fit: cover;
                }
            }

            .button {
                white-space: nowrap;
            }
        }
    }
}

// intermediaire
@media (max-width: 1130px) {
    #desktop-header {
        header {
            padding-left: 25px;
            padding-right: 25px;
            gap: 15px;

            .left {
                gap: 20px;

                nav {
                    gap: 15px;
                }
            }

            .right {
                gap: 10px;

                .social {
                    gap: 5px;
                }
            }
        }
    }
}

// intermediaire
@media (max-width: 1000px) {
    #desktop-header {
        header {
            .left {
                > img {
                    height: 30px;
                }
            }

            .center {
                display: none;
            }
        }
    }
}

// desktop
@media (min-width: $desktop_min_width) {
    .navbar {
        display: none;
    }

    #desktop-header {
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        z-index: $nav-header;
    }
}
