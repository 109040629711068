@import "assets/sass/theme.scss";

.item-and-activity {
    nav {
        display: flex;
        justify-content: center;
        gap: 15px;

        .elmt {
            font-family: "CHEDROS", sans-serif;
            font-weight: bold;
            font-size: 1.4rem;
            color: #080d2680;
            cursor: pointer;

            &.active {
                color: #080d26;

                &::after {
                    content: "";
                    display: block;
                    width: 110%;
                    position: relative;
                    left: -5%;
                    height: 0.3rem;
                    background-color: $primary;
                    border-radius: 0.3rem 0.3rem 0 0;
                }
            }
        }
    }

    > .content {
        margin-top: 10px;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(252px, auto));
        grid-gap: 10px;

        &.activityTab {
            max-height: 300px;
            display: flex;
            gap: 10px;
            flex-direction: column;
            overflow: auto;

            @extend .custom-scrollbar;
        }

        .activity {
            background-color: white;
            border-radius: 8px;
            position: relative;
            padding: 1rem;
            padding-right: 2rem;
            cursor: pointer;

            .main-info {
                font-family: "Config Rounded", sans-serif;
                font-size: 1.4rem;
                font-weight: 500;
                color: $primary;

                .from {
                    max-width: 75px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            .since {
                font-family: "Config Rounded", sans-serif;
                font-size: 1rem;
                color: #080d2680;

                // set to bottom right
                position: absolute;
                right: 1rem;
                bottom: 1rem;
            }

            .see-it {
                margin-top: 1rem;
                font-family: "Config Rounded", sans-serif;
                font-size: 1.2rem;
            }

            .details {
                margin-top: 15px;
                font-family: "Config Rounded", sans-serif;
                color: #080d2680;
            }

            .controls {
                position: absolute;
                top: 1rem;
                right: 1rem;

                .arrow-icon {
                    height: 15px;
                    fill: $primary;
                }
            }
        }
    }
}
