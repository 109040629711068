@import "./../../../assets/sass/var.scss";

.accessories-examples{
    height: 90vw;
    position: relative;
    background-size: cover;
    background-position: center center;
    background-color: #090d26;
    text-align: center;
    >img{
        width: 100%;
        padding-bottom: 35px;
    }
    .hidden{
        display: none;
    }
    .top{
        position: absolute;
        top:20px;
        left:0px;
        display: flex;
        align-items: start;
        justify-content: space-between;
        width: 100%;
        padding: 0 20px;
        .info{
            font-family: "Config Rounded", sans-serif;
            color:white;
            text-align: left;
            p{
                &:first-child{
                    font-weight: 200;
                    font-size: 1.1rem;
                }
                &:last-child{
                    font-size: 1.3rem;
                    margin-top: 9px;
                }
            }
        }
        .reward{
            font-family: "Config Rounded", sans-serif;
            color:white;
            text-align: left;
            display: flex;
            align-items: center;
            p{
                white-space: nowrap;
                font-size: 1.3rem;
            }
            img{
                width: 30px;
                margin-left: 10px;
            }
        }
    }


    >img{
        position: absolute;
        width: 80%;
        left: 50%;
        transform: translateX(-50%);
        bottom: 60px;
    }
    .menu{
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: white;
        width: 100%;
        border-radius: 60px 60px 0 0;
        padding-bottom: 20px;
        p{
            font-family: "CHEDROS", sans-serif;
            font-size:1.4rem;
            font-weight: 700;
            padding: 15px 0 15px 0;
        }
        .img-container{
            img{
                cursor: pointer;
                width: 35px;
                margin: 0 5px;
                filter:grayscale(0.5);
            }
            .active{
                filter:grayscale(0);
                transform: scale(1.1);
            }
        }

    }
}

// desktop
@media (min-width: $desktop_min_width) {
    .accessories-examples{
        height: 46vw;
        .top{
            top:60px;
            left:0px;
            padding: 0 60px;
            .info{

                max-width: 335px;
                p:first-child{
                    font-size: 1.4rem;
                }
                p:last-child{
                    font-size: 2.8rem;
                }
            }
            .reward{
                padding-top: 32px;
                p{
                    margin-top: 0;
                    font-size: 1.8rem;
                }
                img{
                    width: 33px;
                }
            }
        }


        >img{
            padding-bottom: 0;
            bottom: 140px;
        }
        .menu{
            padding-top: 5px;
            .img-container{
                padding: 10px 0;
                img{
                    width: 50px;
                }
            }
        }
    }
}