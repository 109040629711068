@import "assets/sass/var.scss";

.item-slot-card {
    $bg-color: white;

    aspect-ratio: 1/1;
    background-color: $bg-color;
    border-radius: 6px;

    padding: 20px;

    > div {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        gap: 20px;

        height: 100%;

        .title {
            margin-top: 20px;

            font-family: "CHEDROS", sans-serif;
            font-weight: bold;
            font-size: 2rem;
            color: $primary;
            text-align: center;

            flex: 0 1 auto;

            &:hover {
                text-decoration: none !important;
            }
        }

        .icon {
            $margin-y: 20px;
            // margin-top: $margin-y;
            // margin-bottom: $margin-y;

            display: flex;
            justify-content: center;
            align-items: center;

            flex: 1 1 0%;
            overflow: auto;

            img {
                max-height: 100%;
                max-width: 100%;
            }
        }

        .button {
            flex: 0 1 31px;
        }
    }

    &:hover {
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
        background-color: darken($bg-color, 10%);
    }
}

.link:hover {
    text-decoration: none !important;
}
